@import "../../../styles/vars.scss";

.error:not(.white) {
  .labelWrapper {
    color: $color-error;
    &:focus-within {
      color: $color-error;
    }
  }

  .input {
    border: 1px solid $color-error;
    &:focus {
      outline: 2px solid $color-error;
      border-color: transparent;
    }
  }
}

.white {
  .labelWrapper {
    color: $color-white;
    &:focus-within {
      color: $color-white;
    }
  }

  .labelText {
    font-size: 16px;
  }

  .input {
    border: 1px solid $color-white;
    color: $color-white;
    &:focus {
      outline: 2px solid $color-white;
      border-color: transparent;
    }
  }

  .errorText {
    color: $color-white;
  }
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  color: $color-grey-dark;
  &:focus-within {
    color: $color-secondary;
    transition: color 0.1s ease;
    font-weight: 700;
  }
}

.labelText {
  margin-bottom: 3px;
  font-size: 14px;
  display: block;
}

.input {
  padding: 5;
  border: 1px solid $color-grey-light;
  border-radius: 2px;
  height: 30px;
  font-family: $font-source-sans-pro;
  font-size: initial;
  background-color: transparent;
  &:focus {
    outline: 2px solid $color-secondary;
    border-color: transparent;
  }
}

.textarea {
  min-height: 100px;
}

.requiredSign {
  font-size: 14px;
  margin-left: 3px;
  display: inline-block;
}

.errorText {
  display: block;
  font-size: 14px;
  margin-top: 3px;
  color: $color-error;
  min-height: 20px;
}
